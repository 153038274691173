<!--
 * @Author: wxy
 * @Date: 2022-07-25 18:03:22
 * @LastEditTime: 2022-10-26 16:29:04
 * @Description: 招聘信息管理
-->
<template>
  <div class="serverConsultingManagement">
    <ds-header title="招聘信息管理"></ds-header>
    <a-form layout="inline" style="margin-bottom: 10px">
      <a-form-item label="岗位名称：">
        <a-input v-model.trim="searchForm.jobName" placeholder="请输入岗位名称"></a-input>
      </a-form-item>
      <a-form-item label="岗位类型">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.jobTypeId"
        >
          <a-select-option value=""> 全部岗位 </a-select-option>
          <a-select-option v-for="item in jobTypeList" :key="item.id" :value="item.id">
            {{ item.commonsValueName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="就职园区">
        <!-- <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.projectId"
        >
          <a-select-option value=""> 全部项目 </a-select-option>
          <a-select-option v-for="item in projectList" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select> -->
        <!-- <el-cascader v-model="searchForm.projectId" style="width: 200px"
              :options="projectList"
              :props="{emitPath:false,checkStrictly: true , label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable></el-cascader> -->
              <a-cascader expandTrigger="hover" style="width: 100%;" v-model="searchForm.projectId"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="projectList"
          :fieldNames="{ label: 'project_name', children: 'child', value: 'id' }"
          placeholder="请选择就职园区" />
      </a-form-item>
      <a-form-item label="企业名称：">
        <a-input v-model.trim="searchForm.enterpriseName" placeholder="请输入企业名称"></a-input>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button>
      </span>
    </a-form>
    <div style="margin: 10px 0">
      <a-button type="primary" @click="addInfo()"> <a-icon type="plus" /> 新增招聘信息 </a-button>
    </div>
    <a-table
      :rowKey="(record, index) => index"
      :columns="table.columns"
      :data-source="table.tableData"
      :pagination="table.pagination.total ? table.pagination : false"
      @change="pageChange"
      bordered
      :scroll="{ x: 1500 }"
    >
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="isPublish(record)" class="btn"
          >{{ record.publishStatus === 0 ? "取消发布" : "发布" }}
        </a-button>
        <a-button type="link" @click="toDetail(record)" class="btn"> 查看 </a-button>
        <a-button type="link" @click="edit(record)" class="btn"> 编辑 </a-button>
        <a-button type="link" @click="deleteRecruitment(record)" style="color: #f5222d" class="btn"> 删除 </a-button>
      </span>
    </a-table>
  </div>
</template>

<script>
import * as api from "../../../api/socialRecruitingServicesManagement";
import pageRules from "@/utils/pagingConfig/pageUtils.js";
import { getProject} from "@/api/userMessage";
export default {
  name: "recruitmentInformationManagement",
  data() {
    return {
      // 筛选条件
      searchForm: {
        // 岗位名称
        jobName: "",
        // 岗位类型
        jobTypeId: "",
        // 项目ID
        projectId: [],
        // 企业名称
        enterpriseName: "",
      },
      // 岗位类型
      jobTypeList: [],
      // 项目
      projectList: [],
      table: {
        columns: [
          {
            title: "岗位名称",
            dataIndex: "jobName",
            key: "jobName",
            width: 150,
          },
          {
            title: "岗位类型",
            dataIndex: "jobTypeIds",
            key: "jobTypeIds",
            width: 160,
          },
          {
            title: "就职园区",
            dataIndex: "projectName",
            key: "projectName",
            width: 180,
          },
          {
            title: "企业名称",
            dataIndex: "enterpriseName",
            key: "enterpriseName",
            width: 180,
          },
          {
            title: "发布人",
            dataIndex: "publishName",
            key: "publishName",
            width: 150,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "发布时间",
            dataIndex: "publishTime",
            key: "publishTime",
            width: 180,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "最后修改人",
            dataIndex: "modifiedId",
            key: "modifiedId",
            width: 150,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "最后修改时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            width: 180,
            customRender(text) {
              return text ? text : "-";
            },
          },

          {
            title: "状态",
            dataIndex: "publishStatus",
            key: "publishStatus",
            width: 120,
            customRender(text) {
              return text === 0 ? "已发布" : "未发布";
            },
          },
          {
            title: "操作",
            key: "action",
            width: "280px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        tableData: [],
        pagination: {
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
    };
  },
  // 进入路由添加缓存
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("operationCachedRoutes/updateAliveRoutes", ["recruitmentInformationManagement"]);
      if (from.query && from.query.id) {
        vm.getList();
      }
    });
  },
  // 离开清除缓存
  beforeRouteLeave(to, from, next) {
    if (
      to.path !== "/socialRecruitingServicesManagement/recruitmentInformationManagement/detail" &&
      to.path !== "/socialRecruitingServicesManagement/recruitmentInformationManagement/add"
    ) {
      this.$store.dispatch("operationCachedRoutes/updateAliveRoutes", []);
    } else if (to.path === "/socialRecruitingServicesManagement/recruitmentInformationManagement/add" && !to.query.id) {
      this.$store.dispatch("operationCachedRoutes/updateAliveRoutes", []);
    }
    next();
  },
  mounted() {
    // 获取列表
    this.getList();
    // 获取岗位类型
    this.getSelectList();
    // 获取所属项目
    this.getProject();
  },
  methods: {
    // 查询
    search() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    // 重置
    resetSearchForm() {
      this.searchForm = {
        // 岗位名称
        jobName: "",
        // 岗位类型
        jobTypeId: "",
        // 项目ID
        projectId: [],
        // 企业名称
        enterpriseName: "",
      };
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    // 获取列表
    async getList() {
      console.log(this.searchForm);
      try {
        const data = {
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
          ...this.searchForm,
          projectId:this.searchForm.projectId.length?this.searchForm.projectId[this.searchForm.projectId.length-1]:''
        };
        const res = await api.getRecruitmentList(data);
        if (res.code === "200") {
          this.table.tableData = res.data.list;
          this.table.pagination.total = res.data.total;
        }
      } catch (error) {}
    },

    // 新增招聘信息
    addInfo() {
      this.$router.push({
        path: "/socialRecruitingServicesManagement/recruitmentInformationManagement/add",
      });
    },
    // 详情
    toDetail(record) {
      this.$router.push({
        path: "/socialRecruitingServicesManagement/recruitmentInformationManagement/detail",
        query: {
          id: record.recruitId,
        },
      });
    },
    // 编辑
    edit(record) {
      this.$router.push({
        path: "/socialRecruitingServicesManagement/recruitmentInformationManagement/add",
        query: {
          id: record.recruitId,
        },
      });
    },
    // 删除
    deleteRecruitment(record) {
      const _this = this;
      _this.$confirm({
        title: "确认删除",
        content: "删除后该数据无法恢复，确认删除吗？",
        async onOk() {
          try {
            const res = await api.recruitmentDelete(record.recruitId);
            if (res.code === "200") {
              _this.table.pagination.current = pageRules.pageRules(
                _this.table.tableData.length,
                1,
                _this.table.pagination.current
              );
              _this.$message.success("删除成功");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {
            console.log(error);
            _this.$message.error("删除失败");
          }
        },
        onCancel() {},
      });
    },
    // 发布/取消发布
    isPublish(record) {
      const _this = this;
      let title;
      let content;
      let publishStatus = 0;
      if (record.publishStatus === 1) {
        title = "确认发布该招聘信息";
        content = "";
        publishStatus = 0;
      } else {
        title = "确认取消发布该招聘信息";
        content = "";
        publishStatus = 1;
      }
      _this.$confirm({
        title: title,
        content: content,
        async onOk() {
          try {
            const data = {
              recruitId: record.recruitId,
              publishStatus,
            };
            const res = await api.recruitmentPublish(data);
            if (res.code === "200") {
              _this.$message.success("操作成功");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {}
        },
        onCancel() {},
      });
    },
    // 切换分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 获取岗位类型
    async getSelectList() {
      try {
        const res = await api.recruitmentSelectList();
        if (res.code === "200") {
          this.jobTypeList = res.data.jobTypeList;
        }
      } catch (error) {}
    },

    // 获取所属项目
    async getProject() {
      const data = {
        page: 1,
        size: 999,
        tenant_id: "1",
      };
      let res = await  api.getShunyiAllProject()
      if (res.code === "200") {
        this.projectList = res.data;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.btn {
  padding: 4px;
}
</style>