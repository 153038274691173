import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"

// 用户管理列表
export const getUserList = (data) => {
    return fetchApi(`${URL.USER_MESSAGE_LIST}`, { ...data }, "post", "data");
};

//用户详情
export const getUserDetail = (userId) => {
    return fetchApi(`${URL.USER_MESSAGE_DETAIL}?userId=${userId}`, {}, "get");
};

// 编辑用户
export const getUseredit = (data) => {
    return fetchApi(`${URL.USER_MESSAGE_EDIT}`, { ...data }, "post", "data");
};

// 冻结/解冻用户
export const freezeUser = (userId, status) => {
    return fetchApi(`${URL.USER_MESSAGE_FREEZE}?userId=${userId}&status=${status}`, {}, "post", "data");
};

// 导出
export const getUserExport = (data) => {
    return http({
        url: `${URL.USER_MESSAGE_EXPORT}`,
        method: "post",
        data,
        responseType: "blob",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        }
    })
    // return http(`${URL.USER_MESSAGE_EXPORT}`, {...data}, "post", "data", {responseType: "blob",
    // headers: {
    //     "Content-Type": "application/json;charset=utf-8",
    // }});
};
// 查询项目列表
export const getProject = (data) => {
    return fetchApi(`${URL.USER_MESSAGE_PROJECT}`, { ...data }, "post", "data");
};
//协议管理列表
export function agreementList(params) {
    return fetchApi(URL.agreementList, params, 'get')
}
//协议详情
export const findById = (data) => {
    // return fetchApi(`${URL.findById}/userId=${userId}`, {}, "get");
    return fetchApi(URL.findById + data.id, {}, 'get')
};
//添加协议
export function agreementInsert(data) {
    return fetchApi(URL.agreementInsert, data, "post", "data")
}
//协议详情
export const agreementDeleted = (data) => {
    // return fetchApi(`${URL.findById}/userId=${userId}`, {}, "get");
    return fetchApi(URL.agreementDeleted + data.id, {}, 'post')
};
//编辑协议
export function agreementUpdate(data) {
    return fetchApi(URL.agreementUpdate, data, "post", "data")
}

//分页查询城市管理
export function selectCityPage(params) {
    return fetchApi(URL.SELECTCITYPAGE, params, 'get')
}
//修改城市图片 排序
export function updateCityById(data) {
    return fetchApi(URL.UPDATECITYBYID, data, "put", "data")
}
//删除城市图片
export function deleteimagebyid(data) {
    return fetchApi(URL.DELETEIMAGEBYID + '?id=' + data.id, data, 'delete')
}
//查询城市详情
export function selectById(params) {
    return fetchApi(URL.SELECTBYID, params, 'get')
}

//添加通勤巴士
export function insertCommuterBus(data) {
    return fetchApi(URL.INSERTCOMMUTERBUS, data, "post", "data")
}
//查询城市详情
export function getBusById(params) {
    return fetchApi(URL.GETBUSBYID+params.id,params, 'get')
}
//通勤巴士修改
export function updateCommuterBus(data) {
    return fetchApi(URL.UPDATECOMMUTERBUS, data, "put", "data")
}
  
//通勤巴士删除
export function deletedCommuterBus(data) {
    return fetchApi(URL.DELETEDCOMMUTERBUS+ data.id, data, 'delete')
}
//通勤巴士列表
export function getBusList(params) {
    return fetchApi(URL.GETBUSLIST+params.pageNum+'/'+params.pageSize, '', 'get')
}
//通勤巴士修改
export function setSequence(data) {
    return fetchApi(URL.SETSEQUENCE+'?id='+data.id+'&sequence='+data.sequence, data, "post", "data")
}
//通勤巴士获取用户项目
export function getProjectByUserId(params) {
    return fetchApi(URL.GETPROJECTBYUSERID, params, 'get')
}